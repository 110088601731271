
const defaultOgImage = 'https://wettermelden.at/static/media/og-image.png';
const defaultOgUrl = 'https://wettermelden.at';

// Metadata for each route
export const META_DATA = {
  default: { 
    title: 'wettermelden.at', 
    description: 'Default',
    ogTitle: 'wettermelden.at',
    ogImage: defaultOgImage,
    ogUrl: defaultOgUrl,
  },
  reports: { 
    title: 'wettermelden.at - Reports', 
    description: 'Home - Reports',
    ogTitle: 'Report on wettermelden.at!',
    ogImage: defaultOgImage,
    ogUrl: defaultOgUrl,
  },
  report: (report)=>({ 
    title: `wettermelden.at - Report - ${report.title}`, 
    description: `Report: ${report.description}`,
    ogImage: report.imageUrl,
    ogTitle: report.title,
    ogUrl: defaultOgUrl,
  }),
};

export const generateMetaHtml = (metadata) => {
  return `
    <title>${metadata.title}</title>
    <meta name="description" content="${metadata.description}">
    <meta property="og:title" content="${metadata.ogTitle}" />
    <meta property="og:url" content="${metadata.ogUrl}" />
    <meta property="og:image" content="${metadata.ogImage}" />
  `;
}

// Inject metadata into the HTML template
export const injectMetadata = (html, metadata) => {
  const metaHtml = generateMetaHtml(metadata);
  return html.replace(
    '<title>METADATA</title>',
    metaHtml
  )
}
