import objectPath from 'object-path'
import assert from 'browser-assert'
import format from 'date-fns/format/index.js'
import getYear from 'date-fns/get_year/index.js'
import de from 'date-fns/locale/de/index.js'
import en from 'date-fns/locale/en/index.js'

/*
class ReportPresentation manages properties used to represent a report,
that is it has for each parameter and all possible values of parameters:
  - title
  - description
  - image
  - imageUrl

These properties are associated to a given parameter as well as its values, for example:
  - eventType.description: description of parameter eventTpye
  - eventType.values.hail.description: description of value hail of parameter eventType

These properties can be defined in different contexts, like for example the map.
So the key paths for a certain parameter become for example:
- map.eventType.values.hail.description
- default.eventType.values.flood.image

There is a default context. Optionally, properties not defined in other contexts default to the
values of the default context.

*/
class ReportPresentation {
  /*
  constructs a ReportPresentation

  config is a JavaScript object used to initialize the ReportPresentation,
  which can be more efficient than using the setters to set values

  if inheritDefault is set to true, values will be taken from the
  default context if they don't exist in a given context

  example config:
    {
      default: {
        eventType: {
          title: 'REPORT.EVENT_TYPE.TITLE',
          description: 'REPORT.EVENT_TYPE.DESCRIPTION',
          values: {
            hail: {
              title: 'REPORT.EVENT_TYPE.HAIL.TITLE',
              description: 'REPORT.EVENT_TYPE.HAIL.DESCRIPTION',
              image: pngHail,
              imageUrl: '/assets/icons/hail.png'
            }
          }
        }
      },
      map: {
        eventyType: {
          values: {
            hail: {
              imageUrl: '/map/005-hail.png'
            }
          }
        }
      }
    }
  };
  */
  constructor (reportingParameters) {
    this.reportingParameters = reportingParameters
    this.buildIndex(reportingParameters)

  }

  setReportingParameters (reportingParameters) {
    this.buildIndex(reportingParameters)
    this.addParentLinks(reportingParameters)
  }

  buildIndex(reportingParameters) {
    this.index = {}
    this.doBuildIndex(reportingParameters, '')
    Object.freeze(this.index)
  }

  doBuildIndex(parameters, keyPrefix) {
    for (let parameter of parameters) {
      const { apiParameterName, apiParameterValue } = parameter

      let key = keyPrefix
      if (apiParameterName && apiParameterValue) {
        if (key !== '') {
          key += '.'
        }
        key = key + `${apiParameterName}.${apiParameterValue}`
        const propertiesKey = `${key}.$properties`

        objectPath.set(this.index, propertiesKey, parameter)
      }

      const children = parameter.children
      if (children && Array.isArray(children) && children.length > 0 ) {
        this.doBuildIndex(children, key)
      }
    }
  }

  addParentLinks(reportingParameters) {
    this.doAddParentLinks(reportingParameters, null)
  }

  doAddParentLinks(parameters, parent) {
    for (let i = 0; i < parameters.length; i++) {
      const p = parameters[i]
      p.parent = parent

      const children = p.children
      if (children && Array.isArray(children) && children.length > 0) {
        this.doAddParentLinks(children, p)
      }
    }
  }

  // getters for properties of parameter, e.g. description of eventType

  // gets the title of a parameter named parameterName, in the given context
  getTitleForParameter (parameterName, context = 'default') {
    return this.getPropertyForParameter('title', parameterName, context)
  }

  // gets the description of a parameter named parameterName, in the given context
  getDescriptionForParameter (parameterName, context = 'default') {
    return this.getPropertyForParameter('description', parameterName, context)
  }

  // gets the imageUrl of a parameter named parameterName, in the given context
  getImageUrlForParameter (parameterName, context = 'default') {
    return this.getPropertyForParameter('imageUrl', parameterName, context)
  }

  // gets the image of a parameter named parameterName, in the given context
  getImageForParameter (parameterName, context = 'default') {
    return this.getPropertyForParameter('image', parameterName, context)
  }

  // getters for properties of parameter values, e.g. description of the value flood of parameter eventType

  // gets the title of a parameter named parameterName with a value parameterValue, in the given context
  getTitleForParameterValue (parameterName, parameterValue, context = 'default') {
    const title = this.getPropertyForParameterValue('title', parameterName, parameterValue, context) ||
    parameterValue
    return title
  }

  // gets the description of a parameter named parameterName with a value parameterValue, in the given context
  getDescriptionForParameterValue (parameterName, parameterValue, context = 'default') {
    return this.getPropertyForParameterValue('description', parameterName, parameterValue, context)
  }

  // gets the imageUrl of a parameter named parameterName with a value parameterValue, in the given context
  getImageUrlForParameterValue (parameterName, parameterValue, context = 'default') {
    return this.getPropertyForParameterValue('imageUrl', parameterName, parameterValue, context)
  }

  // gets the image of a parameter named parameterName with a value parameterValue, in the given context
  getImageForParameterValue (parameterName, parameterValue, context = 'default') {
    return this.getPropertyForParameterValue('image', parameterName, parameterValue, context)
  }

  // setters for properties of parameters, e.g. setting the description of parameter eventType

  // sets the title of a parameter named parameterName, in the given context
  setTitleForParameter (title, parameterName, context = 'default') {
    this.setPropertyForParameter('title', title, parameterName, context)
  }

  // sets the description of a parameter named parameterName, in the given context
  setDescriptionForParameter (description, parameterName, context = 'default') {
    this.setPropertyForParameter('description', description, parameterName, context)
  }

  // sets the imageUrl of a parameter named parameterName, in the given context
  setImageUrlForParameter (imageUrl, parameterName, context = 'default') {
    this.setPropertyForParameter('imageUrl', imageUrl, parameterName, context)
  }

  // sets the image of a parameter named parameterName, in the given context
  setImageForParameter (image, parameterName, context = 'default') {
    this.setPropertyForParameter('image', image, parameterName, context)
  }

  // setters for properties of parameter values, e.g. setting the description of the value flood of parameter eventType

  // sets the title of a parameter named parameterName with a value parameterValue, in the given context
  setTitleForParameterValue (title, parameterName, parameterValue, context = 'default') {
    this.setPropertyForParameterValue('title', title, parameterName, parameterValue, context)
  }

  // sets the description of a parameter named parameterName with a value parameterValue, in the given context
  setDescriptionForParameterValue (description, parameterName, parameterValue, context = 'default') {
    this.setPropertyForParameterValue('description', description, parameterName, parameterValue, context)
  }

  // sets the imageUrl of a parameter named parameterName with a value parameterValue, in the given context
  setImageUrlForParameterValue (imageUrl, parameterName, parameterValue, context = 'default') {
    this.setPropertyForParameterValue('imageUrl', imageUrl, parameterName, parameterValue, context)
  }

  // sets the image of a parameter named parameterName with a value parameterValue, in the given context
  setImageForParameterValue (image, parameterName, parameterValue, context = 'default') {
    this.setPropertyForParameterValue('image', image, parameterName, parameterValue, context)
  }

  // methods for presenting reports
  // these contain some logic taking into account if the reports have event-specific parameters set

  // gets the title of the event, which corresonds to the value of the event type parameter
  // for example "Rain and/or Snow"
  getTitleForParameterSelection (parameterSelection) {
    if (parameterSelection.at(-1) && parameterSelection.at(-1).parameterSelection && parameterSelection.at(-1).parameterSelection.title) {
      return parameterSelection.at(-1).parameterSelection.title
    } else {
      const key = this.buildKey(parameterSelection.slice(0,1))
      const properties = objectPath.get(this.index, key)
      if (properties && properties.title){

        return properties.title
      }

      return null
    }

    // this.reportBuilder.report = report
    // return this.getTitleForParameterValue('eventType', report.eventType)
  }

  getTitleForReport (report) {
    const parameterSelection = this.getParameterSelection(report)
    return this.getTitleForParameterSelection(parameterSelection)
  }

  // gets the subtitle of the event, which corresponds to the value of an event-specific parameter value, if it exists
  // returns null otherwise
  // example return value (after running through t function) "Drizzle"
  getSubtitleForParameterSelection (parameterSelection) {
    if (parameterSelection.length < 2) { return null }

    const titles = []

    for (let i = 1; i < parameterSelection.length; i++) {
      const subSelection = parameterSelection.slice(0, i + 1)
      if (parameterSelection.at(-1) && parameterSelection.at(-1).presentation && parameterSelection.at(-1).presentation.title) {
        const title = parameterSelection.at(-1).presentation.title
        titles.push(title)
      } else {
        const key = this.buildKey(subSelection)
        const properties = objectPath.get(this.index, key)
        const fullTitle = this.getFullTitle(properties)
        const title = fullTitle.join(' > ')
        // const title = this.getTitleForParameterSelection(subSelection)
        titles.push(title)
      }
    }

    return titles.filter((v, i, a) => a.indexOf(v) === i).join(' > ')
  }

  getFullTitle(properties) {
    const titles =  [properties.title]

    let p = properties
    while (p.parent) {
      p = p.parent
      if (!p.apiParameterName || !p.apiParameterValue) {
        titles.unshift(p.title)
      }
    }

    return titles.filter((v, i, a) => a.indexOf(v) === i)
  }

  getFullDescription(properties) {
    const descriptions = []

    if (properties.subtitle) {
      descriptions.unshift([properties.subtitle])
    }

    let p = properties
    while (p.parent) {
      p = p.parent
      if (p.subtitle) {
        descriptions.unshift(p.subtitle)
      }
    }

    return descriptions
  }

  getSubtitleForReport (report) {
    const parameterSelection = this.getParameterSelection(report)
    return this.getSubtitleForParameterSelection(parameterSelection)
  }

  // gets the geolocation information of the report, that is city and/or country
  getGeolocationStringForReport(report) {
    let locationString = null
    if (report.city) {
      locationString = report.city
    }
    if (report.country) {
      if (!locationString) {
        locationString = ''
      }
      locationString += ', ' + report.country
    }
    return locationString
  }

  getDateTimeStringForReport(report, locale = 'de') {
    const locales = { de, en }

    const dateFormatWithYear = 'dddd, DD.MM.YYYY | HH:mm'
    const dateFormatWithoutYear = 'dddd, DD.MM. | HH:mm'

    const date = report.createdAt

    let dateFormat = dateFormatWithoutYear
    if (getYear(new Date()) !== getYear(date)) {
      dateFormat = dateFormatWithYear
    }

    return format(date, dateFormat, { locale: locales[locale] })
  }

  // gets a description of the report's parameter value(s), to be displayed as part of the report details
  // if specific is true and there is an event-specific parameter value, the description of this value will be returned if it exists
  // it specific is false, or there is not event-specific parameter value, or there is no description thereof,
  // the description of the event type's value is returned
  // if no description is found, null is returned
  // example return value (after running through t function): "Dry or end of precipitation or expected precipitation not yet started."
  getDescriptionForParameterSelection (parameterSelection) {
    const key = this.buildKey(parameterSelection)

    const properties = objectPath.get(this.index, key)
    if (properties && properties.subtitle){
      return properties.subtitle
    }

    return null
  }

  getDescriptionForReport (report) {
    const parameterSelection = this.getParameterSelection(report)
    return this.getDescriptionForParameterSelection(parameterSelection)
  }

  getFullDescriptionForParameterSelection(parameterSelection) {
    const key = this.buildKey(parameterSelection)

    const properties = objectPath.get(this.index, key)

    return this.getFullDescription(properties)
  }

  getFullDescriptionForReport(report) {
    const parameterSelection = this.getParameterSelection(report)
    return this.getFullDescriptionForParameterSelection(parameterSelection)
  }

  // gets the image (icon) representative of the report's values
  // if specific is true and there is an event-specific parameter value, the image for this parameter value will be returned
  // if specific is false, or there is no event-specific parameter valuem, or there is no image representative thereof,
  // the image representative of the event type's value is returned
  // if no image is found, null is returned
  // example return values: image representative of "Rain and/or Snow", image representativre of "Drizzle"
  getImageForParameterSelection (parameterSelection) {
    if (parameterSelection.at(-1) && parameterSelection.at(-1).presentation && parameterSelection.at(-1).presentation.icon) {
      return parameterSelection.at(-1).presentation.icon[0].url
    } else {
      const key = this.buildKey(parameterSelection)

      const properties = objectPath.get(this.index, key)
      if (properties && properties.icon && Array.isArray(properties.icon)
          && properties.icon.length > 0 && properties.icon[0].url){
        return properties.icon[0].url
      }

      return null
    }
  }

  getImageForReport (report) {
    const parameterSelection = this.getParameterSelection(report)
    return this.getImageForParameterSelection(parameterSelection)
  }

  // private

  setPropertyForParameter (propertyName, propertyValue, parameterName, context = 'default') {
    assert(
      propertyName !== undefined &&
      propertyValue !== undefined &&
      parameterName !== undefined &&
      context !== undefined
    )

    const key = `${context}.${parameterName}.${propertyName}`
    objectPath.set(this.contexts, key, propertyValue)
  }

  getPropertyForParameter (propertyName, parameterName, context = 'default') {
    assert(
      propertyName !== undefined &&
      parameterName !== undefined &&
      context !== undefined
    )

    const key = `${context}.${parameterName}.${propertyName}`
    let propertyValue = objectPath.get(this.contexts, key)

    if (propertyValue === undefined && this.inheritDefault && context !== 'default') {
      const key = `default.${parameterName}.${propertyName}`
      propertyValue = objectPath.get(this.contexts, key)
    }

    return propertyValue
  }

  setPropertyForParameterValue (propertyName, propertyValue, parameterName, parameterValue, context = 'default') {
    assert(
      propertyName !== undefined &&
      propertyValue !== undefined &&
      parameterName !== undefined &&
      parameterValue !== undefined &&
      context !== undefined
    )

    const key = `${context}.${parameterName}.values.${parameterValue}.${propertyName}`
    objectPath.set(this.contexts, key, propertyValue)
  }

  getPropertyForParameterValue (propertyName, parameterName, parameterValue, context = 'default') {
    assert(
      propertyName !== undefined &&
      parameterName !== undefined &&
      parameterValue !== undefined &&
      context !== undefined
    )

    const key = `${context}.${parameterName}.values.${parameterValue}.${propertyName}`
    let propertyValue = objectPath.get(this.contexts, key)

    if (propertyValue === undefined && this.inheritDefault && context !== 'default') {
      const key = `default.${parameterName}.values.${parameterValue}.${propertyName}`
      propertyValue = objectPath.get(this.contexts, key)
    }

    return propertyValue
  }

  buildKey (parameterSelection) {
    return parameterSelection.map(({name, value}) => `${name}.${value}`).join('.') + '.$properties'
  }

  getParameterSelection (report) {
    return this.doGetParameterSelection(report, [], this.index)
  }

  doGetParameterSelection (report, parameterSelection, index) {
    const keys = Object.keys(index).filter(k => !k.startsWith('$'))
    if (keys.length === 0) { return parameterSelection }

    const key = keys[0]
    if (!report.hasOwnProperty(key)) { return parameterSelection }

    const value = report[key]
    if (!index[key].hasOwnProperty(value)) { return parameterSelection }

    const selection = {name: key, value}
    const newSelection = [...parameterSelection, selection]
    const newIndex = index[key][value]
    return this.doGetParameterSelection(report, newSelection, newIndex)
  }
}

export default ReportPresentation
