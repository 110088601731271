import React, { Component } from 'react'
import Paho from 'paho-mqtt'
import { subSeconds } from 'date-fns'
import ReportBuilder from './ReportBuilder'
import AppService from './AppService'
import AuthService from './AuthService'
import ReportService from './ReportService'
import SavedReportsService from './SavedReportsService'
import ReportRepository from './ReportRepository'
import RegionService from './RegionService'
import NewsletterService from './NewsletterService'
import translationService from './TranslationService'
import ReportPresentation from './ReportPresentation'
import config from '../config/config'
import { baseSchema, eventSpecificSchemas } from '../config/schemas'
// import en from '../config/translations/en.json'
// import configContextDefault from '../config/reportPresentationConfigContextDefault'
import { centerRegionToApiRegion } from '../lib/utils/regionMath'
import { timespanStringToSeconds } from '../lib/utils/time'
import ApolloClient from 'apollo-boost'
import { gql } from "apollo-boost"

const reportQuery = (site) => gql`query ReportingParameters {
  entries (site:["${site}"], section: "reportingParameters", level: 1) {
    ... on reportingParameters_reportingParameter_Entry {
      id
      title
      subtitle
      apiParameterName
      apiParameterValue
      icon {
        url
      }
    }

    children {
      ... on reportingParameters_reportingParameter_Entry {
        id
        title
        subtitle
        apiParameterName
        apiParameterValue
        icon {
          url
        }
      }

      children {
        ... on reportingParameters_reportingParameter_Entry {
          id
          title
          subtitle
          apiParameterName
          apiParameterValue
          icon {
            url
          }
        }
      }
    }
  }
}
`

// const reportPresentationConfig = {
//   default: configContextDefault
// }

const Context = React.createContext()

class Provider extends Component {
  constructor (props) {
    super(props)
    this.bindMethods()

    const toDate = new Date()
    const fromDate = subSeconds(new Date(), timespanStringToSeconds('3h'))
    const playbackTime = fromDate

    this.state = {
      user: { username: null }, // current user
      finishedLoadingUserData: false,
      finishedLoadingRegion: false,
      reportingParameters: [],
      reports: [], // current reports
      savedReports: [], // saved reports (outbox)
      newReport: {}, // report to be built and submitted
      selectedParameters: [],
      timespan: '24h',
      toDate,
      fromDate,
      playbackTime,
      region: config.fallbackRegion,
      showList: false,
      showHistory: false,
      showMovie: false,
      userLocation: null,
      locale: config.locale,
      warningStatusVisible: false
    }

    const { mqtt, locale } = config
    const { port, host, useSSL, topic } = mqtt

    const random = randomString(16)
    const clientId = 'awob-app-' + random
    const mqttClient = new Paho.Client(host, port, clientId)
    const mqttOptions = { useSSL, topic }

    this.appService = new AppService()
    this.authService = new AuthService(config.appApiRoot, config.idApiRoot, this.setUser, locale)
    this.reportService = new ReportService(config.reportsApiRoot, this.authService)
    this.reportRepository = new ReportRepository(
      this.reportService,
      mqttClient,
      mqttOptions,
      this.getReports,
      this.setReports
    )
    this.newsletterService = new NewsletterService(config.newsletterSubscribeUrl)

    const { timeouts } = config
    this.savedReportsService = new SavedReportsService({
      getReports: this.getSavedReports,
      setReports: this.setSavedReports,
      createReport: this.reportService.create.bind(this.reportService),
      createReportImage: this.reportService.createImage.bind(this.reportService),
      timeoutCreateReport: timeouts.createReport,
      timeoutUploadReportImage: timeouts.uploadReportImage
    })

    this.reportBuilder = new ReportBuilder(baseSchema, eventSpecificSchemas)
    this.reportPresentation = new ReportPresentation([])
    this.translationService = translationService
    this.regionService = RegionService

    const { cmsApiRoot } = config
    this.graphQLClient = new ApolloClient({
      uri: cmsApiRoot,
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache'
        },
        mutate: {
          fetchPolicy: 'no-cache'
        },
        watchQuery: {
          fetchPolicy: 'no-cache'
        }
      }
    });

    this.initAsync().catch((e) => {})

    this.translationService.onChange((locale) => {
      this.loadReportingParameters(locale)
    })
  }

  render () {
    const value = {
      state: this.state,
      fetchReports: this.fetchReports, // this.reportRepository.fetchReports.bind(this.reportRepository),
      fetchWarnings: this.fetchWarnings,
      fetchReport: this.reportService.get.bind(this.reportService),
      createReport: this.reportService.create.bind(this.reportService),
      createReportImage: this.reportService.createImage.bind(this.reportService),
      reportBuilder: this.reportBuilder,
      reportPresentation: this.reportPresentation,
      regionService: this.regionService,
      subscribeToNewsletter: this.newsletterService.subscribe.bind(this.newsletterService),
      setNewReport: this.setNewReport,
      setSelectedParameters: this.setSelectedParameters,
      setTimespan: this.setTimespan,
      setFromDate: this.setFromDate,
      setToDate: this.setToDate,
      setPlaybackTime: this.setPlaybackTime,
      setRegion: this.setRegion,
      getUser: this.getUser,
      setUser: this.setUser,
      setShowList: this.setShowList,
      setShowHistory: this.setShowHistory,
      setShowMovie: this.setShowMovie,
      setUserLocation: this.setUserLocation,
      setWarningStatusVisible: this.setWarningStatusVisible,
      translationService: this.translationService,
      t: this.translationService.translate.bind(this.translationService),
      appService: this.appService,
      // initAsync: this.initAsync,
      authService: this.authService,
      savedReportsService: this.savedReportsService,
      formatDate4Api: this.reportService.formatDate4Api.bind(this.reportService),
      graphQLClient: this.graphQLClient
    }

    return (
      <Context.Provider value={value}>
        {this.props.children}
      </Context.Provider>
    )
  }

  setNewReport (report) {
    this.setState({ newReport: report })
  }

  setSelectedParameters (selectedParameters) {
    this.setState({ selectedParameters })
  }

  setTimespan (value) {
    this.setState({ timespan: value })
  }

  setFromDate (date) {
    this.setState({ fromDate: date })
  }

  setToDate (date) {
    this.setState({ toDate: date })
  }

  setPlaybackTime (time) {
    this.setState({ playbackTime: time })
  }

  setRegion (region) {
    this.setState({ region: region })
  }

  setShowList (value) {
    this.setState({ showList: value })
  }

  setWarningStatusVisible(value) {
    this.setState({ warningStatusVisible: value })
  }

  setShowHistory (value) {
    this.setState({ showHistory: value })
  }

  setShowMovie (value) {
    this.setState({ showMovie: value })
  }

  setUserLocation (value) {
    this.setState({ userLocation: value })
  }

  // auth

  setUser (user) {
    return this.setState({ user })
  }

  getUser () {
    return this.state.user
  }

  fetchReports (options) {
    const defaultOptions = {
      region: this.state.region,
      showHistory: this.state.showHistory,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    }
    const mergedOptions = Object.assign({}, defaultOptions, options)
    const { region, showHistory, fromDate, toDate } = mergedOptions

    const { latMin, lngMin, latMax, lngMax } = centerRegionToApiRegion(region)

    if (showHistory) {
      this.reportRepository.fetchReports(fromDate, toDate, latMin, lngMin, latMax, lngMax)
    } else {
      const { timespan } = config
      this.reportRepository.fetchRecentReports(timespan, latMin, lngMin, latMax, lngMax)
    }
  }

  async fetchWarnings() {
    return fetch('https://warnungen.zamg.at/wsapp/api/getWarnstatus')
  }

  // private

  getReports () {
    return this.state.reports
  }

  setReports (reports) {
    this.setState({ reports })
  }

  getSavedReports () {
    return this.state.savedReports
  }

  setSavedReports (reports) {
    this.setState({ savedReports: reports })
  }

  bindMethods () {
    this.getReports = this.getReports.bind(this)
    this.setReports = this.setReports.bind(this)
    this.getSavedReports = this.getSavedReports.bind(this)
    this.setSavedReports = this.setSavedReports.bind(this)
    this.setNewReport = this.setNewReport.bind(this)
    this.setSelectedParameters = this.setSelectedParameters.bind(this)
    this.setFromDate = this.setFromDate.bind(this)
    this.setToDate = this.setToDate.bind(this)
    this.setPlaybackTime = this.setPlaybackTime.bind(this)
    this.setRegion = this.setRegion.bind(this)
    this.getUser = this.getUser.bind(this)
    this.setUser = this.setUser.bind(this)
    this.setUserLocation = this.setUserLocation.bind(this)
    this.fetchReports = this.fetchReports.bind(this)
    this.fetchWarnings = this.fetchWarnings.bind(this)
    this.setShowList = this.setShowList.bind(this)
    this.setWarningStatusVisible = this.setWarningStatusVisible.bind(this)
    this.setShowHistory = this.setShowHistory.bind(this)
    this.setShowMovie = this.setShowMovie.bind(this)
    this.setTimespan = this.setTimespan.bind(this)
    this.initAsync = this.initAsync.bind(this)
  }

  initAsync () {
    // load auth token and profile
    const pAuth = this.authService.loadFromStorage()
    pAuth.then(() => {
      const profile = this.authService.getProfile()
      if (profile) {
        this.setUser(profile)
      }
    }).catch((e) => {
    }).finally(() => {
      this.setState({
        finishedLoadingUserData: true
      })
    })

    // load region
    const pRegion = this.regionService.load()
    pRegion.then((region) => {
      this.setRegion(region)
    }).catch(() => {}).finally(() => {
      this.setState({
        finishedLoadingRegion: true
      })
    })

    // load saved reports
    const pSavedReports = this.savedReportsService.loadAllReportsFromStorage()
    pSavedReports.catch((e) => { })

    // load reporting parameters shifted to callback of translation service, loaded when locale
    // is loaded from storage or on changes
    const pReportingParemeters = this.loadReportingParameters (config.locale)
    return Promise.all([pAuth, pRegion, pSavedReports, pReportingParemeters ])
  }

  loadReportingParameters (locale) {
    const siteMap = {
      'de': 'trustedSpotterAustriaDe',
      'en': 'trustedSpotterAustriaEn'
    }
    const site = siteMap[locale]
    const pParameters = this.graphQLClient
      .query({
        query: reportQuery(site),
      })

    pParameters.then(reportingParameters => {
      const params = reportingParameters.data.entries

      this.reportPresentation.setReportingParameters(params)

      this.setState({
        reportingParameters: params
      })
    }).catch(() => {})

    return pParameters
  }
}

function randomString (length) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export default Context
export { Provider }
